import React, { ElementType, FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
// import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { fetch_one, fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LockIcon from '@mui/icons-material/Lock';

import {
  Box,
  Grid,
  Tooltip,
  useMediaQuery,
} from '@mui/material';

import {
  Dt,
  Typography
} from './styled';

import { EhealthMail } from '../../models/EhealthMail';

import HelpersEhealth from '../../actions/helpers/ehealth';

const { DateTime } = require("luxon");

type Props = {
  mail: EhealthMail,
  onClick?: () => void;
};

export const InboxEhealthMail: FunctionComponent<Props> = ({mail, onClick}) => {
  const theme = useTheme();
  const [login, setLogin] = useAtom(loginAtom);
  const {t, i18n} = useTranslation(['translations']);
  const navigate = useNavigate();
  const matches = useMediaQuery('(min-width:900px)');

  // const [search, setSearch] = useState<string>('');








  // useEffect(() => {
  //   let to = setTimeout(() => {
  //     // refetch
  //   }, 2000);
  
  //   return () => {
  //     clearTimeout(to);
  //   }
  // }, [search]);

  let subject = mail.content_data?.title;
  let body = !!mail.content_data?.payload ? mail.content_data.payload.replace(/<[^>]*>?/gm, '') : '';
  if (!!mail.is_draft) {
    if (!!mail.extra_data && !!mail.extra_data.draft_info && !!mail.extra_data.draft_info.attributes) {
      if (!!mail.extra_data.draft_info.attributes.subject) subject = mail.extra_data.draft_info.attributes.subject.replace(/<[^>]*>?/gm, '');
      if (!!mail.extra_data.draft_info.attributes.body) body = mail.extra_data.draft_info.attributes.body.replace(/<[^>]*>?/gm, '');
    }
  }
  if (!subject) subject = `<geen onderwerp>`;
  if (!body) body = `<geen inhoud>`;
  
  return (<>
    <Grid container spacing={0} sx={{
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.02)'
      }
    }} onClick={(e) => {(mail.is_draft ? navigate("/ehealth_inbox/new", {state: {mail: mail}}) : !!onClick ? onClick() : undefined);}}>
      <Grid item xs={12} sx={{marginTop: 0.25, marginBottom: 0.25}}>
        <Grid container spacing={0}>

          <Grid item md={3} xs={12} style={{}}>
            <Box sx={{
              fontSize: '0.9rem',
              '& span': {
                maxWidth: '100%',
                display: 'block'
              },
              ...(!mail.unread ? {} : {
                fontWeight: 'bold'
              }),
              position: 'relative'
            }}>
              <Typography noWrap>
                {(mail.content_data?.type !== "DOCUMENT") && <DeleteForeverIcon
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // if (!!this.props.onRemove) this.props.onRemove(e);
                  }}
                  sx={{
                    opacity: 1,
                    color: 'lightgrey',
                    position: 'absolute',
                    right: 8,
                    top: 0,
                    height: 24
                  }}
                />}
                {(mail.box === "inbox" || mail.box === "drafts" || !mail.box) && (!!mail.sender && !!mail.sender.actor) && <>{mail.sender.actor.organizationName}{mail.sender.actor.firstName} {mail.sender.actor.lastName}</>}
                {(mail.box === "sent" || mail.box === "deleted") && (mail.recipients || []).map(x => [!!x.person ? x.person.firstName : x.identifiers.entity, !!x.person ? x.person.lastName : `(${HelpersEhealth.get_quality(x.identifiers.quality)})`].join(" ")).join("; ")}
                {!!mail.content_data?.encrypted && <LockIcon style={{scale: '0.8', float: 'right', display: 'inline-block', color: 'grey'}} />}
                {((!!mail.content_data?.metadata && !!mail.content_data.metadata?.linked_client_info) || (!!mail.content_data?.extensions && !!mail.content_data.extensions.patientNiss)) && <AssignmentIndIcon style={{scale: '0.8', float: 'right', display: 'inline-block', color: (!!mail.content_data.metadata && !!mail.content_data.metadata.linked_client_info && !!mail.content_data.metadata.linked_client_info.valid_practice ? theme.palette.primary.main : 'grey')}} />}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={9} xs={12}>
            <Box style={{display: 'flex', flex: 10, flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'nowrap', maxWidth: '100%'}}>

              <Box sx={{width: `calc(100% - 105px)`}}>
                <Box sx={{display: 'flex', flex: 10, flexDirection: 'row', justifyContent: 'flex-start', flexWrap: {md: 'nowrap', xs: 'wrap'}, maxWidth: '100%'}}>
                  {matches && <>
                    <Box sx={{
                      fontSize: '0.9rem',
                      '& span': {
                        maxWidth: '100%',
                        display: 'block'
                      },
                      maxWidth: '40%',
                      display: 'inline-block',
                      ...(!mail.unread ? {} : {
                        fontWeight: 'bold'
                      })
                    }}>
                      <Typography noWrap>
                        {!!mail.is_draft && <>[DRAFT] </>}
                        {subject}
                      </Typography>
                    </Box>
                    <FiberManualRecordIcon sx={{fontSize: '0.3rem', margin: '7px 5px', width: 5, color: 'rgba(0, 0, 0, 0.2)'}} />
                    <Box sx={{
                      fontSize: '0.9rem',
                      '& span': {
                        maxWidth: '100%',
                        display: 'block',
                        color: 'rgba(0, 0, 0, 0.4)'
                      },
                      flex: '1 0 auto',
                      flexBasis: 0,
                      minWidth: 0,
                      display: 'inline-block'
                    }}>
                      <Typography noWrap>{body}</Typography>
                    </Box>
                  </>}

                  {!matches && <>
                    <Box sx={{
                      fontSize: '0.9rem',
                      '& span': {
                        maxWidth: '100%',
                        display: 'block'
                      },
                      maxWidth: '100%',
                      width: '100%',
                      display: 'block',
                      ...(!mail.unread ? {} : {
                        fontWeight: 'bold'
                      })
                    }}>
                      <Typography noWrap>
                        {!!mail.is_draft && <>[DRAFT] </>}
                        {subject}
                      </Typography>
                    </Box>
                    <Box sx={{
                      fontSize: '0.9rem',
                      '& span': {
                        maxWidth: '100%',
                        display: 'block',
                        color: 'rgba(0, 0, 0, 0.4)'
                      },
                      maxWidth: '100%',
                      width: '100%',
                      display: 'block'
                    }}><Typography noWrap>{body}</Typography></Box>
                  </>}
                </Box>
              </Box>

              <Box sx={{
                fontSize: '0.9rem',
                flex: '0 0 50px',
                alignSelf: 'flex-start',
                maxHeight: 40,
                textAlign: 'right',
                marginLeft: 50,
                [theme.breakpoints.up('md')]: {
                  flex: '0 0 100px',
                  maxHeight: 20,
                  marginLeft: 0
                },
                display: 'inline-block',
                '& span': {
                  maxWidth: '100%',
                  display: 'block'
                },
                position: 'relative'
              }}>

                <Box sx={{
                  fontSize: '0.9rem',
                  width: 50,
                  float: 'left',
                  display: 'inline-block',
                  '& span': {
                    maxWidth: '100%',
                    display: 'block'
                  },
                  ...(!mail.unread ? {} : {
                    fontWeight: 'bold'
                  })
                }}>
                  <Typography>
                    {DateTime.now().toFormat("D") === DateTime.fromISO(mail.created_at).toFormat("D") && <Dt d={mail.created_at} f="t" />}
                    {DateTime.now().toFormat("D") !== DateTime.fromISO(mail.created_at).toFormat("D") && <Dt d={mail.created_at} f="d LLL" />}
                  </Typography>
                  {(!mail.extra_data?.last_touch || DateTime.fromISO(mail.extra_data.last_touch) < DateTime.now().minus({day: 1})) && <Tooltip title={t("shared.ehealth_mail.warning.mail_deleted_on_ehealth")}>
                    <BrokenImageIcon style={{opacity: 0.5, color: 'lightgrey', position: 'absolute', right: 0, top: -2, height: 24}} />
                  </Tooltip>}
                </Box>
                
              </Box>

            </Box>
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  </>);
}
