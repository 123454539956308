import React, { FunctionComponent } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
// import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_one, fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

// import {
//   Box,
//   Divider,
//   Grid,
//   Paper,
//   Tab,
//   Tabs,
// } from '@mui/material';

import {
  Page
} from '../../components/v2/styled';
import { EhealthInbox } from '../../components/v2/EhealthInbox';

import HelpersPractice from '../../actions/helpers/practice';

// const { DateTime } = require("luxon");

type Props = {}

export const EhealthMail: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  // const navigate = useNavigate();
  // const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const is_ehealth_mail = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'ehealth'});

  // const [currentTab, setCurrentTab] = useState<number>(!!state && !!state.tab ? state.tab : 0);
  
  return <Page
    premiumGatekeeping={(login?.type === "User::Coach" && !is_ehealth_mail)}
    premiumMode='ehealth'
    sx={{padding: {xs: 0, md: 3}}}
  >
    <EhealthInbox />
  </Page>;
}

